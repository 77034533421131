import Vue from 'vue';
import wrap from '@vue/web-component-wrapper';

// Import FontAwesome icons
import './icons.js';

import { importGlobalComponents } from './components/globals';
importGlobalComponents();

import { setupFormulate } from './formulate';
setupFormulate();

import { installConstantsPlugin } from './plugins/constants';
installConstantsPlugin(); // Install support for constants inside vue components

import i18n from './i18n';
const VueExtended = Vue.extend({ i18n });

const recaptchaScript = document.createElement('script');
recaptchaScript.setAttribute(
  'src',
  `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_RECAPTCHA_SITE_KEY}`
);
recaptchaScript.setAttribute('async', 'async');
document.head.appendChild(recaptchaScript);

// The code below registers all the web components, creates dummy views for them and returns the router.

export const isRegistered = name => {
  // Ref: https://stackoverflow.com/a/28210364
  return document.createElement(name).constructor !== HTMLElement;
};

export const registerElement = (name, component) => {
  if (!isRegistered(name)) {
    const WrappedComponent = wrap(VueExtended, component);
    window.customElements.define(name, WrappedComponent);
    return true;
  }
  return false;
};

const routeLinks = [];
export const routes = [];

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
  // Look for files in the web-components directory
  './web-components',
  // Do not look in subdirectories
  false,
  // Only include .vue files
  /[\w-]+\.vue/,
  'lazy'
);

// For each matching file name...
requireComponent.keys().forEach(filePath => {
  // Get the PascalCase version of the component name
  const componentName = filePath
    // Remove the "./" from the beginning
    .replace(/^\.\//, '')
    // Remove the file extension from the end
    .replace(/\.\w+$/, '');

  const webComponentName =
    'dew-wc-' +
    componentName
      // Convert PascalCase to kebab-case
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      // Convert to lower case
      .toLowerCase();

  // Register the web-component
  const webComponent = () => requireComponent(filePath);
  registerElement(webComponentName, webComponent);

  routes.push({
    path: '/' + webComponentName,
    name: componentName,
    component:
      process.env.NODE_ENV === 'production'
        ? Vue.component(componentName, {
            // Production: Create a dummy view to nest the web component.
            render: function(createElement) {
              return createElement(webComponentName);
            }
          })
        : webComponent // Locally: Use the component directly to test it.
  });

  // Push link to the above route to the array routeLinks.
  routeLinks.push({ name: componentName });
});

routes.push({
  path: '/',
  name: 'Home',
  component: Vue.component('Home', {
    render: function(createElement) {
      return createElement(
        'div',
        {
          class: 'my-10 mx-auto max-w-4xl flex flex-wrap gap-4'
        },
        routeLinks.map(function(to) {
          return createElement(
            'router-link',
            {
              attrs: { to },
              class:
                'p-4 h-20 w-auto grid place-items-center border rounded shadow-md hover:shadow-lg active:shadow-md'
            },
            to.name
          );
        })
      );
    }
  })
});

window.seti18nLang(process.env.VUE_APP_I18N_LOCALE);

export default VueExtended;
