import Vue from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { config, library } from '@fortawesome/fontawesome-svg-core';

// Regular icons
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons/faArrowDown';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons/faArrowUp';
import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars';
import { faBellOn } from '@fortawesome/pro-regular-svg-icons/faBellOn';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faCircleInfo as farFaCircleInfo } from '@fortawesome/pro-regular-svg-icons/faCircleInfo';
import { faCloudArrowDown } from '@fortawesome/pro-regular-svg-icons/faCloudArrowDown';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import { faFile } from '@fortawesome/pro-regular-svg-icons/faFile';
import { faHouse } from '@fortawesome/pro-regular-svg-icons/faHouse';
import { faIndustry } from '@fortawesome/pro-regular-svg-icons/faIndustry';
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons/faLocationDot';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons/faPaperPlane';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faPhone } from '@fortawesome/pro-regular-svg-icons/faPhone';
import { faPhoneArrowDownLeft } from '@fortawesome/pro-regular-svg-icons/faPhoneArrowDownLeft';
import { faSignHanging } from '@fortawesome/pro-regular-svg-icons/faSignHanging';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons/faSpinnerThird';
import { faTag } from '@fortawesome/pro-regular-svg-icons/faTag';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding';
import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage';
import { faEuroSign } from '@fortawesome/pro-regular-svg-icons/faEuroSign';
import { faPercent } from '@fortawesome/pro-regular-svg-icons/faPercent';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { faComments } from '@fortawesome/pro-regular-svg-icons/faComments';
import { faEnvelopeCircleCheck } from '@fortawesome/pro-regular-svg-icons/faEnvelopeCircleCheck';
import { faBed } from '@fortawesome/pro-regular-svg-icons/faBed';

// Solid icons
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons/faCircleInfo';
import { faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons/faPaperPlaneTop';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons/faEllipsis';

// Light icons
import { faArrowUp as falFaArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowUp';

// Thin icons
import { faBath } from '@fortawesome/pro-thin-svg-icons/faBath';
import { faBedFront } from '@fortawesome/pro-thin-svg-icons/faBedFront';
import { faBenchTree } from '@fortawesome/pro-thin-svg-icons/faBenchTree';
import { faBuilding as fatFaBuilding } from '@fortawesome/pro-thin-svg-icons/faBuilding';
import { faCalendar } from '@fortawesome/pro-thin-svg-icons/faCalendar';
import { faCar } from '@fortawesome/pro-thin-svg-icons/faCar';
import { faFire } from '@fortawesome/pro-thin-svg-icons/faFire';
import { faRulerCombined } from '@fortawesome/pro-thin-svg-icons/faRulerCombined';
import { faCheck as falFaCheck } from '@fortawesome/pro-thin-svg-icons/faCheck';

// Brand icons
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';

library.add(
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  falFaArrowUp,
  faBars,
  faBath,
  faBedFront,
  faBellOn,
  faBenchTree,
  faBuilding,
  fatFaBuilding,
  faCalendar,
  faCar,
  faCheck,
  falFaCheck,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleCheck,
  faCircleXmark,
  faCircleExclamation,
  faCircleInfo,
  farFaCircleInfo,
  faCloudArrowDown,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFile,
  faFire,
  faHouse,
  faIndustry,
  faLink,
  faLocationDot,
  faPaperPlane,
  faPhone,
  faPhoneArrowDownLeft,
  faPencil,
  faRulerCombined,
  faSignHanging,
  faSpinnerThird,
  faTag,
  faTimes,
  faTrash,
  faUser,
  faXmark,
  faGoogle,
  faApple,
  faFacebookF,
  faImage,
  faEuroSign,
  faCalendarAlt,
  faPercent,
  faPaperPlaneTop,
  faComments,
  faEllipsis,
  faPlus,
  faMinus,
  faEnvelopeCircleCheck,
  faBed
);

// As shown here: https://fontawesome.com/docs/web/use-with/vue/use-with#web-components-with-vue-web-component-wrapper
// To tell Font Awesome to skip auto-inserting CSS into the <head>, we already include the css in main.css
config.autoAddCss = false;

// Globally register the component.
Vue.component('font-awesome-icon', FontAwesomeIcon);
